import I18n from 'i18n-js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ErrorStateList from '../utils/ErrorStateList.js';
import {
  Logger,
  DeviceManager,
  FeatureDetector,
  FullscreenHelper,
  LocalStorage,
  SystemPressureMonitor,
  StreamHelpers,
} from 'eyeson';
import { withSnackbar } from 'notistack';

import MobileRoom from './MobileRoom.js';
import DesktopRoom from './DesktopRoom.js';
import ActionFactory from '../utils/ActionFactory.js';
import KeyboardControl from '../utils/KeyboardControl.js';
import ActivityMonitor from '../utils/ActivityMonitor.js';
import BroadcastHelper from '../utils/BroadcastHelper.js';
import SpeechHelper from '../utils/SpeechHelper.js';
import { matchMedia } from '../utils/LayoutHelper.js';
import supports from '../utils/SupportHelper.js';
import instanceHelper from '../utils/eyesonInstanceHelper.js';
import tabAudioHelper from '../utils/eyesonTabAudioHelper.js';
import micIndicator from '../utils/MicIndicator.js';
import PipControlsHelper from '../utils/PipControlsHelper.js';
import WebHIDHelper from '../utils/WebHIDHelper.js';
import InterCommunicationHelper from '../utils/InterCommunicationHelper.js';
import StreamdeckHelper from '../utils/StreamdeckHelper.js';
import beforeUnloadHelper from '../utils/BeforeUnloadHelper.js';

const _maxRestartAttempts = 1;

class Room extends Component {
  constructor(props) {
    super(props);

    this.mql = matchMedia('(max-height: 560px)');

    const {
      eyeson,
      recording,
      snapshots,
      broadcasts,
      connecting,
      participants,
      mediaOptions,
      videoSources,
      virtualBackground,
      screenshareUserPreventLayout,
      config: {
        times: { room: inactivityTimer },
      },
    } = this.props;

    this.state = {
      solo: false,
      user: eyeson.user,
      peer: null,
      isPip: false,
      isLocked: false,
      sinkId: 'default',
      poster: '',
      stream: null,
      options: {},
      restore: {},
      messages: [],
      minified: this.mql.matches,
      sortOrder: [],
      snapshots: snapshots,
      accepted: false,
      connecting: connecting,
      broadcasts: broadcasts,
      facingMode: 'user',
      localStream: null,
      presentationStream: null,
      canvasPresentation: { files: [] },
      mediaPresentation: { file: null },
      participants: participants,
      sources: [],
      videoSources: videoSources,
      audioSupport: true,
      videoSupport: true,
      sessionCount: 0,
      isFullscreen: FeatureDetector.isFullscreen(),
      notifications: { audio: [], lastPlay: Date.now() },
      broadcastHelper: new BroadcastHelper(eyeson),
      showLocalStream: false,
      hasMutedVideoPeers: false,
      forwardedVideoMuted: false,
      wasVideoActive: mediaOptions.video,
      specialExit: null,
      newAudioDevice: null,
      toggleVideoDisabled: false,
      isChangingStream: false,
      customOptions: {
        hideRecording: props.hideRecording,
      },
      active: {
        audio: mediaOptions.audio,
        video: mediaOptions.video,
        screenAsVideo: false,
        snapshots: false,
        recording: recording && !recording.duration,
        sfu: false,
        presenter: null,
        isPresenter: false,
        live_stream: false,
        hasPresenter: false,
        screen_capture: false,
        hasAudioPosition: false,
        talkingMuted: false,
        push_to_talk: false,
        screenAddition: false,
        tabAudio: false,
        isPresenting: false,
      },
      visible: {
        panel: '',
        dialog: '',
        right_sidebar: false,
        reaction_picker: false,
        mobile_participants_list: false,
        mobile_connection_info: false,
      },
      playback: {
        gifs: [],
        files: [],
        playing: [],
        preview: null,
      },
      recording: {
        id: recording ? recording.id : null,
        user: recording ? recording.user.name : null,
        createdAt: recording ? recording.created_at : null,
      },
      liveStream: {
        user: null,
        phase: 'initial',
        activated: [],
      },
      voiceActivity: {
        user: {},
        on: false,
      },
      virtualBackground: virtualBackground,
      remoteMute: {
        user: {},
      },
      kickUser: {
        user: {},
      },
      speech: null,
      restartAttempt: 0,
      connectionStatistics: null,
      pipCam: {
        available: false,
        active: false,
        autoStart: false,
        request: false,
      },
      youtubeUserToken: null,
      pressureState: null,
      quality: null,
      audioPassthrough: mediaOptions.audioPassthrough,
    };

    this.keyboardControl = null;
    this.actionFactory = new ActionFactory();
    this.deviceManager = new DeviceManager();
    this.activityMonitor = new ActivityMonitor(inactivityTimer);
    this.fullscreenHelper = new FullscreenHelper();
    this.systemPressure = new SystemPressureMonitor({ sampleInterval: 3000 });
    instanceHelper.setOptions({ screenshareUserPreventLayout });
  }

  /**
   * Create a connection to the room and listen for any events
   * that occur.
   **/
  componentDidMount() {
    const { config, eyeson, environment } = this.props;

    document.title = (eyeson.room.name || 'eyeson room') + ' | eyeson';
    StreamdeckHelper.updateDocumentTitle();

    if (config.locked === true) {
      this.setState({ isLocked: config.locked });
    }

    // Setup event listeners
    eyeson.onEvent(this.handleEyesonEvent);
    SpeechHelper.onEvent(this.handleEvent);
    instanceHelper.onEvent(this.handleEvent);
    tabAudioHelper.onEvent(this.handleEvent);

    this.keyboardControl = new KeyboardControl(this.handleEvent);
    this.deviceManager.onChange(this.handleDeviceChange);
    this.fullscreenHelper.onChange((isFullscreen) => {
      InterCommunicationHelper.onFullscreen(isFullscreen);
      this.setState({ isFullscreen });
    });
    if (environment.isPhone === false) {
      micIndicator.init();
      beforeUnloadHelper.enable();
    }
    this.setPiPControls();
    this.activityMonitor.onInactivity((msg) => {
      this.handleEvent(
        Object.assign({ activityMonitor: this.activityMonitor }, msg)
      );
    });
    this.mql.addEventListener('change', this.onMediaQueryChange);

    DeviceManager.getSinkId().then((sinkId) => this.setState({ sinkId }));
    this.deviceManager.watchForNewDevices();
    this.activityMonitor.start();
    InterCommunicationHelper.onEvent(this.handleEvent);
    WebHIDHelper.setMuteActive(!this.state.active.audio);
    WebHIDHelper.setCallActive(true);
    WebHIDHelper.onEvent(this.handleWebhidEvents);
    StreamdeckHelper.setActions({ joinleave: { enabled: true, state: false } });
    if (FeatureDetector.canMonitorSystemPressure()) {
      this.systemPressure.onUpdate((state) => {
        this.setState({ pressureState: state });
      });
      this.systemPressure
        .start()
        .catch(() => console.error('systemPressure error'));
    }
    // Connect to the room
    const mediaOptions = {
      audio: this.state.active.audio,
      video: this.state.active.video,
      eco: this.props.mediaOptions.eco,
      audioPassthrough: this.state.audioPassthrough,
    };
    setMediaOptions(this, mediaOptions);
    if (this.props.vbgAvailable) {
      mediaOptions.virtualBackground = this.props.virtualBackground !== 'off';
    }
    eyeson.join(mediaOptions);
    eyeson.send({ type: 'fetch_room' });
  }

  componentDidUpdate(_prevProps, prevState) {
    const {
      participants,
      user,
      toggleVideoDisabled,
      active: { screen_capture },
      videoSupport,
    } = this.state;
    const {
      mediaOptions: { eco },
    } = this.props;
    if (participants.length !== prevState.participants.length) {
      const peer = participants.find((p) => p.apiId !== user.apiId) || null;
      this.setState({ peer });
    }
    if (eco || screen_capture || toggleVideoDisabled || !videoSupport) {
      PipControlsHelper.clearCamera();
    } else {
      PipControlsHelper.setCamera(() => {
        this.handleEvent({ type: 'toggle_video' });
      });
    }
  }

  /**
   * Clean-up after meeting has finished.
   **/
  componentWillUnmount() {
    const { eyeson } = this.props;

    eyeson.offEvent(this.handleEyesonEvent);
    SpeechHelper.offEvent(this.handleEvent);
    instanceHelper.offEvent(this.handleEvent);
    instanceHelper.stop();
    tabAudioHelper.offEvent(this.handleEvent);
    tabAudioHelper.stop();
    eyeson.destroy();
    WebHIDHelper.setMuteActive(false);
    WebHIDHelper.setCallActive(false);
    WebHIDHelper.destroy();

    let speech = SpeechHelper.stopDetection(this.state);
    this.setState({ speech });
    micIndicator.terminate();
    this.deviceManager.stop();
    this.fullscreenHelper.off();
    this.keyboardControl.destroy();
    this.mql.removeEventListener('change', this.onMediaQueryChange);
    this.actionFactory = null;
    InterCommunicationHelper.offEvent(this.handleEvent);
    beforeUnloadHelper.disable();
    this.systemPressure.destroy();
  }

  isVisible = (element) => this.state.visible[element] || false;

  resetActivityMonitor = () => this.activityMonitor.reset();

  setPiPControls = () => {
    const { toggleVideoDisabled, videoSupport } = this.state;
    const {
      mediaOptions: { eco },
    } = this.props;
    PipControlsHelper.setMicrophone(() => {
      this.handleEvent({ type: 'toggle_audio' });
    });
    if (!(eco || toggleVideoDisabled) && videoSupport) {
      PipControlsHelper.setCamera(() => {
        this.handleEvent({ type: 'toggle_video' });
      });
    }
    PipControlsHelper.setHangup(() => {
      this.handleEvent({ type: 'toggle_pip' });
      setTimeout(() => this.handleEvent({ type: 'exit_room' }), 100);
    });
    if (supports.AutoPip) {
      PipControlsHelper.setAutoPipHandler(() => {
        this.handleEvent({ type: 'toggle_pip' });
      });
      const setting = LocalStorage.load('autopip', { enabled: false });
      if (setting.enabled) {
        PipControlsHelper.enableAutoPip();
      }
    }
  };

  setOpenPanel = (newPanel) => {
    this.setState({
      visible: update(this.state.visible, {
        panel: {
          $set: newPanel,
        },
      }),
    });
  };

  /**
   * We want to keep track of device changes (disconnected cams/mics)
   * during the session.
   **/
  handleDeviceChange = (newState) => {
    if (!newState.cameras) {
      return;
    }
    this.handleEvent({
      type: 'device_support',
      videoSupport: (newState.cameras || this.state.videoSupport).length > 0,
      audioSupport:
        (newState.microphones || this.state.audioSupport).length > 0,
      silenced: true,
    });
  };

  tryRestartSession = () => {
    const { eyeson, token } = this.props;
    const roomOnConnect = (event) => {
      if (event.connectionStatus === 'ready') {
        eyeson.offEvent(roomOnConnect);
        eyeson.send({ type: 'fetch_room' });
      } else if (ErrorStateList.includes(event.connectionStatus)) {
        this.props.onExit({ reason: 'offline' });
      }
    };
    const mediaOptions = {
      audio: this.state.active.audio,
      video: this.state.active.video,
      eco: this.props.mediaOptions.eco,
      audioPassthrough: this.state.audioPassthrough,
    };
    if (this.props.vbgAvailable) {
      mediaOptions.virtualBackground = this.state.virtualBackground !== 'off';
    }
    eyeson.offEvent(this.handleEyesonEvent);
    eyeson.destroy();
    eyeson.onEvent(roomOnConnect);
    eyeson.onEvent(this.handleEyesonEvent);
    eyeson.start(token, mediaOptions);
  };

  /**
   * eyeson event handler
   * update event message for lock feature
   **/
  handleEyesonEvent = (event) => {
    if (event.type === 'error' && event.name === 'ice_failed') {
      if (this.state.restartAttempt < _maxRestartAttempts) {
        Logger.warn('Room Session Restart');
        this.setState({ restartAttempt: this.state.restartAttempt + 1 });
        SpeechHelper.stopDetection(this.state);
        this.handleEvent({ type: 'reset_room_state' });
        this.tryRestartSession();
        return;
      }
    }
    if (event.type === 'exit') {
      const { eyeson } = this.props;
      if (this.state.specialExit) {
        eyeson.offEvent(this.handleEyesonEvent);
        this.props.onExit(this.state.specialExit);
        return;
      }
      if (eyeson.shutdownBy || event.reason === 'bye') {
        event.reason = 'end_meeting';
      } else if (
        Array.isArray(eyeson.kicked) &&
        eyeson.kicked.some((entry) => entry.userId === this.state.user.clientId)
      ) {
        event.reason = 'meeting_locked';
      }
    }
    if (event.type === 'statistics_ready') {
      event.statistics.onUpdate(this.handleStatisticsEvent);
    }
    this.handleEvent(event);
  };

  /**
   * When an event occurs use the action factory to lookup the action
   * to be executed. Note that those actions are state-aware and
   * can access any room information, as well as change the current
   * state.
   *
   * The action processing is in paranoia mode - any unhandled error will get
   * caught here, not to break the interface.
   *
   * @see https://facebook.github.io/react/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous
   **/
  handleEvent = (msg) => {
    if (!this.actionFactory) {
      Logger.warn('Room::handleEvent without actionFactory');
      return;
    }

    let action = this.actionFactory.get(msg);
    this.setState((prevState, props) => {
      try {
        this.resetActivityMonitor();
        return action.process(prevState, props, this.handleEvent);
      } catch (error) {
        Logger.error(error);
        return { warning: I18n.t('error:msg:unknown') };
      }
    });
  };

  handleStatisticsEvent = (data) => {
    this.setState({ connectionStatistics: data });
  };

  onMediaQueryChange = (event) => {
    this.handleEvent({ type: 'toggle_minified', minified: event.matches });
  };

  handleWebhidEvents = (event) => {
    const { type } = event;
    if (type === 'togglecall' && event.active === false) {
      this.handleEvent({ type: 'exit_room' });
    } else if (type === 'togglemute') {
      this.handleEvent({ type: 'toggle_audio' });
    } else if (type === 'error') {
      this.props.enqueueSnackbar(event.message, {
        variant: 'warning',
        autoHideDuration: 5 * 1000,
      });
    }
  };

  render() {
    const { environment } = this.props;

    if (environment.isPhone) {
      return (
        <MobileRoom
          {...this.props}
          {...this.state}
          isVisible={this.isVisible}
          handleEvent={this.handleEvent}
          activityMonitor={this.activityMonitor}
        />
      );
    }
    return (
      <DesktopRoom
        {...this.props}
        {...this.state}
        isVisible={this.isVisible}
        handleEvent={this.handleEvent}
        setOpenPanel={this.setOpenPanel}
        activityMonitor={this.activityMonitor}
      />
    );
  }
}

const parseApiOptions = (str) => {
  const result = {};
  str.split(';').forEach((entry) => {
    const [key, value] = entry.split('=');
    result[key] = value;
  });
  return result;
};

const setMediaOptions = (that, mediaOptions) => {
  if (that.props.apiMediaOptions) {
    try {
      const params = parseApiOptions(that.props.apiMediaOptions);
      if (params.codec && FeatureDetector.canSetCodecs()) {
        const codec = StreamHelpers.getSenderCodecs('video').filter(
          ({ mimeType }) => mimeType === params.codec
        );
        if (codec) {
          mediaOptions.codecSelection = { video: codec };
        }
      }
      if (params['max-bitrate-video'] || params['max-bitrate-audio']) {
        mediaOptions.sendEncodings = {};
        mediaOptions.receiveConstraints = {};
        if (params['max-bitrate-audio']) {
          const maxBitrate = +params['max-bitrate-audio'];
          mediaOptions.sendEncodings.audio = { maxBitrate };
          mediaOptions.receiveConstraints.audio = { maxBitrate };
        }
        if (params['max-bitrate-video']) {
          const maxBitrate = +params['max-bitrate-video'];
          mediaOptions.sendEncodings.video = { maxBitrate };
          mediaOptions.receiveConstraints.video = { maxBitrate };
        }
      }
      return;
    } catch (error) {
      Logger.warn('setMediaOptions', error);
    }
  }
  // const vp8 = RTCRtpSender.getCapabilities('video').codecs.filter(({ mimeType }) => mimeType === 'video/VP8');
  // const vp9 = RTCRtpSender.getCapabilities('video').codecs.filter(({ mimeType }) => mimeType === 'video/VP9');
  // const av1 = RTCRtpSender.getCapabilities('video').codecs.filter(({ mimeType }) => mimeType === 'video/AV1');
  // const h264 = RTCRtpSender.getCapabilities('video').codecs.filter(({ mimeType }) => mimeType === 'video/H264')[0];
  // h264.length=6;
  // mediaOptions.codecSelection = { video: [h264] };
  // mediaOptions.codecSelection = { video: av1 };
  // mediaOptions.sendEncodings = { video: { scaleResolutionDownBy: 1.33333333 } }
  if (that.props.mediaOptions.quality === 'low') {
    mediaOptions.sendEncodings = {
      audio: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_AUDIO },
      video: {
        maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_VIDEO,
      },
    };
    mediaOptions.receiveConstraints = {
      audio: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_AUDIO },
      video: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_VIDEO },
    };
    instanceHelper.setOptions({ quality: 'low' });
    that.setState({ quality: 'low' });
  }
  if (that.props.mediaOptions.quality === 'medium') {
    mediaOptions.sendEncodings = {
      video: {
        maxBitrate: +process.env.REACT_APP_DATASAVER_MEDIUM_VIDEO,
      },
    };
    mediaOptions.receiveConstraints = {
      video: { maxBitrate: +process.env.REACT_APP_DATASAVER_MEDIUM_VIDEO },
    };
    instanceHelper.setOptions({ quality: 'medium' });
    that.setState({ quality: 'medium' });
  }
};

/**
 * Note: some of these props are not passed along to other components,
 * but are required in specific actions instead (e.g. onExit).
 **/
Room.propTypes = {
  audio: PropTypes.bool,
  video: PropTypes.bool,
  config: PropTypes.object,
  onExit: PropTypes.func.isRequired,
  eyeson: PropTypes.object.isRequired,
  logoUrl: PropTypes.string,
  recording: PropTypes.object,
  broadcasts: PropTypes.array,
  environment: PropTypes.object.isRequired,
};

Room.defaultProps = {
  audio: true,
  video: true,
  connecting: true,
  participants: [],
  videoSources: [],
  mediaOptions: {
    audio: true,
    video: true,
    eco: false,
    audioPassthrough: false,
  },
  config: { times: { room: 10 } },
  experimentalFeatures: false,
};

export default withSnackbar(Room);
